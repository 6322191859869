import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    background: {
      default: "#fff"
    },
    primary: {
      contrastText: "#fff",
      main: "#96bc3c"
    },
    secondary: {
      main: "#306ebb"
    },
    common: {
      light: "#f4f6f3",
      dark: "#1b2122"
    },
    text: {
      disabled: "rgba(27, 33, 34, 0.38)",
      hint: "rgba(27, 33, 34, 0.38)",
      primary: "rgba(27, 33, 34, 0.87)",
      secondary: "rgba(27, 33, 34, 0.54)"
    }
  },
  typography: {
    fontFamily: `"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif`,
    h1: {
      fontWeight: 600,
      fontSize: "3.75rem"
    },
    h2: {
      fontWeight: 600,
      fontSize: "3.25rem"
    },
    h3: {
      fontWeight: 600,
      fontSize: "2.75rem"
    },
    h4: {
      fontWeight: 400,
      fontSize: "2rem"
    },
    h5: {
      fontWeight: 400,
      fontSize: "1.5rem"
    },
    h6: {
      fontWeight: 400
    }
  }
});

export default theme;
