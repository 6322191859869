import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import React from "react";
import { Helmet } from "react-helmet";
import theme from "../../src/utils/theme";

const TopLayout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    {children}
  </ThemeProvider>
);

export default TopLayout;
