// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-general-template-js": () => import("/opt/build/repo/src/templates/generalTemplate.js" /* webpackChunkName: "component---src-templates-general-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-asset-finance-js": () => import("/opt/build/repo/src/pages/asset-finance.js" /* webpackChunkName: "component---src-pages-asset-finance-js" */),
  "component---src-pages-bridging-loan-js": () => import("/opt/build/repo/src/pages/bridging-loan.js" /* webpackChunkName: "component---src-pages-bridging-loan-js" */),
  "component---src-pages-business-banking-js": () => import("/opt/build/repo/src/pages/business-banking.js" /* webpackChunkName: "component---src-pages-business-banking-js" */),
  "component---src-pages-business-loan-js": () => import("/opt/build/repo/src/pages/business-loan.js" /* webpackChunkName: "component---src-pages-business-loan-js" */),
  "component---src-pages-business-saving-js": () => import("/opt/build/repo/src/pages/business-saving.js" /* webpackChunkName: "component---src-pages-business-saving-js" */),
  "component---src-pages-buy-to-let-js": () => import("/opt/build/repo/src/pages/buy-to-let.js" /* webpackChunkName: "component---src-pages-buy-to-let-js" */),
  "component---src-pages-cash-flow-js": () => import("/opt/build/repo/src/pages/cash-flow.js" /* webpackChunkName: "component---src-pages-cash-flow-js" */),
  "component---src-pages-commercial-mortgage-js": () => import("/opt/build/repo/src/pages/commercial-mortgage.js" /* webpackChunkName: "component---src-pages-commercial-mortgage-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invoice-finance-js": () => import("/opt/build/repo/src/pages/invoice-finance.js" /* webpackChunkName: "component---src-pages-invoice-finance-js" */),
  "component---src-pages-our-team-js": () => import("/opt/build/repo/src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-pricing-js": () => import("/opt/build/repo/src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-property-development-js": () => import("/opt/build/repo/src/pages/property-development.js" /* webpackChunkName: "component---src-pages-property-development-js" */),
  "component---src-pages-property-finance-js": () => import("/opt/build/repo/src/pages/property-finance.js" /* webpackChunkName: "component---src-pages-property-finance-js" */)
}

